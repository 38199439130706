import React from 'react'
import { Link } from 'react-router-dom';

function speakerapplicatons() {
   return (
      <div className='w-full font-body px-4 py-20'>
         <div className='max-w-[1280px] mx-auto'>
            <div className="w-full font-body text-white">
               <div className="max-w-[850px] mx-auto">
                  <div className="text-center">
                     <h1 className="text-5xl font-bold uppercase pb-5 max-md:text-4xl">2025 Speaker Applications</h1>
                     <p className="pb-5">Futurist Conference brings together 250+ speakers every year to discuss and debate how future technologies impact the social, economic, environmental and political landscape of our global economy.</p>
                     <div className="w-[350px] mx-auto max-sm:w-[100%]">
                        <Link><button-gradient><p>Apply to be a Speaker</p></button-gradient></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default speakerapplicatons