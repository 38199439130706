import * as React from 'react'
import featuredImage from '../img/og-live-preview.jpg'


export const FeaturedImage = (props) => (
   <>
      {
         props.customImage ? (
            <meta property='og:image' content={props.customImage} />
         )
            :
            (
               <meta name="og:image" content={'https://blockmountaincnx.com/static/media/og-live-preview.jpg' + featuredImage} />
            )
      }
   </>
)

export default FeaturedImage