import React from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';
import Footer from '../components/footer.jsx'
import ScrollToTop from '../components/scrolltotop.jsx'

function speakersessions2019() {
   return (
      <div className='w-full bg-gradient-to-r from-[#040227] via-[#1C005B] to-[#040227] font-body px-4'>
         <div className='max-w-[1280px] mx-auto'>
            <ScrollToTop />

            {/* Navigation */}
            <div className="max-w-[1280px] mx-auto text-white grid grid-cols-6 gap-6 pt-40">
               <Link to='/speakersessions2019'><div className="flex justify-center bg-pink-600 rounded-full py-2 cursor-pointer hover:bg-opacity-100 ease-out duration-300">
                  <h3>2019</h3>
               </div></Link>

               <Link to='/speakersessions2020'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2020</h3>
               </div></Link>

               <Link to='/speakersessions2021'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2021</h3>
               </div></Link>

               <Link to='/speakersessions2022'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2022</h3>
               </div></Link>

               <Link to='/speakersessions2023'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2023</h3>
               </div></Link>

               <Link to='/speakersessions2024'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2024</h3>
               </div></Link>
            </div>

            {/* Title */}
            <div className='items-center pt-20'>
               <h2 className='text-5xl font-bold text-white uppercase text-center'>Block Mountain 2019</h2>
            </div>

            {/* Main VDO */}
            <div className='pt-20'>
               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/aL_xRi7Gho8?si=5U4wogE4ieYnPiSr'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>
            </div>

         </div>

         <Footer />
      </div>
   )
}

export default speakersessions2019