import React from 'react'
import PhotoSlider from '../components/photoslider'
import ReactPlayer from 'react-player'

function block_about_event() {
   return (
      <div className='max-w-[1280px] mx-auto font-body mt-10 px-4'>

         <div className='mx-auto grid grid-cols-2 gap-5 max-lg:grid-cols-1'>
            <div>
               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player'
                     url='https://fb.watch/qLPybwTzvZ/'

                     width='100%'
                  />
               </div>
            </div>
            <div>
               <h3 className='uppercase text-sm font-bold text-gray-300'>January 25-28 | Chiangmai</h3>
               <h1 className='uppercase text-3xl font-extrabold pb-5 pt-2 text-[#110066]'>Block Mountain 2024</h1>
               <p className=' text-sm text-justify'>Block Mountain is the space or platform that brings everyone together. Only when we were featured on Block Mountain, the group we were discussing were industry insiders, startup, government sector and education sector. And we have received support from people in the industry to help with content creation. When the event starts, their fans will come to listen. Like the event held last year Live broadcast for 10 hours, 60 speakers, 50 lectures about blockchain and digital assets. Even if not listening to all topics Or just listen for a short period of only 10-30 minutes per session. But it's about listening to the real people, the real voices of the industry. The evening event was a party to thank the speakers. Thank you to the people in the industry who came to support the event. This allows for a network of like-minded people to expand cooperation in various fields more widely and more diversely.</p>
            </div>
         </div>

         <div>
            <PhotoSlider />
         </div>

      </div>
   )
}

export default block_about_event