import React from 'react'
import { Link } from 'react-router-dom';
import Eventpop from '../img/icon-eventpop.svg';

function buyticket() {
   return (
      <div className='w-full bg-gradient-to-r from-[#040227] via-[#1C005B] to-[#040227] font-body px-4 pt-10 '>
         <div className='max-w-[1280px] mx-auto flex flex-col text-center'>
            <h2 className='text-white uppercase font-bold text-2xl'>last 3 years we have tickets <span className='text-cyan-500'>sold out</span> before the event dates...</h2>
            <h3 className='text-white uppercase text-2xl'>buy your ticket now to hold the place</h3>
            <div className='w-[350px] mx-auto pt-10 max-sm:w-[100%]'>
               <Link to='https://www.eventpop.me/e/16509/https-blockmountaincnx-com' target="_blank">
                  <button-gradient>
                     <img src={Eventpop} height={120} width={23} />
                     <p className='pl-3 text-white uppercase'>Buy Tickets</p>
                  </button-gradient>
               </Link>
            </div>

         </div>
      </div>
   )
}

export default buyticket