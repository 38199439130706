import React from "react";
import Nimman from "../img/venue/nimman.jpg";


function venue() {
  return (
    <div className="w-full bg-gradient-to-r from-[#040227] via-[#1C005B] to-[#040227] font-body">
      <div className="max-w-[full] mx-auto flex flex-col">
        <div className="flex flex-col text-center">
          <h2 className="text-3xl font-bold uppercase text-white mb-10">
            Venue Event Space Chiangmai
          </h2>
          <img className="full-screen bg-cover bg-center bg-fixed" src={Nimman} />
        </div>
      </div>
    </div>
  );
}

export default venue;
