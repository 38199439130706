import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Photo1 from '../img/image-slider/image-slider-01.jpg'
import Photo2 from '../img/image-slider/image-slider-02.jpg'
import Photo3 from '../img/image-slider/image-slider-03.jpg'
import Photo4 from '../img/image-slider/image-slider-04.jpg'
import Photo5 from '../img/image-slider/image-slider-05.jpg'
import Photo6 from '../img/image-slider/image-slider-06.jpg'



function photoslider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,

    autoplay: true,
    pauseOnHover: true,
    speed: 2000,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1675,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  

  return (
    <div className='mx-auto my-10 px-4'>

      <Slider {...settings}>

        <div className='pl-1'>
          <img src={Photo1} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo2} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo3} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo4} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo5} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo6} className='' />
        </div>

      </Slider>
    </div>
  )
}


export default photoslider