import React from 'react'
import Days from '../img/icons/icon-days.svg'
import Exibitors from '../img/icons/icon-exibitors.svg'
import Session from '../img/icons/icon-session.svg'
import Speakers from '../img/icons/icon-speaker.svg'
import Attendees from '../img/icons/icon-attendees.svg'
import AttendeesOnline from '../img/icons/icon-attendees-online.svg'

function statistic() {
   return (
      <div className='w-full bg-gradient-to-r from-[#040227] via-[#1C005B] to-[#040227] font-body px-4 py-10'>
         <div className='max-w-[1280px] mx-auto grid grid-cols-11 gap-5 items-center max-md:grid-cols-2 max-md:gap-y-5 max-md:gap-x-5'>

            <div className='flex flex-col justify-center items-center'>
               <img src={Days} height={26} width={26} />
               <p className='text-cyan-500 text-sm py-2'>Days</p>
               <h3 className='text-white text-4xl font-bold'>4</h3>
            </div>

            <div className='flex justify-center max-md:hidden'>
               <div className='w-[1px] h-[30px] bg-blue-300'></div>
            </div>

            <div className='flex flex-col justify-center items-center'>
               <img src={Exibitors} />
               <p className='text-cyan-500 text-sm py-2'>Exibitors</p>
               <h3 className='text-white text-4xl font-bold'>15+</h3>
            </div>
            <div className='flex justify-center max-md:hidden'>
               <div className='w-[1px] h-[30px] bg-blue-300'></div>
            </div>
            <div className='flex flex-col justify-center items-center'>
               <img src={Session} />
               <p className='text-cyan-500 text-sm py-2'>Session</p>
               <h3 className='text-white text-4xl font-bold'>50+</h3>
            </div>
            <div className='flex justify-center max-md:hidden'>
               <div className='w-[1px] h-[30px] bg-blue-300'></div>
            </div>
            <div className='flex flex-col justify-center items-center'>
               <img src={Speakers} />
               <p className='text-cyan-500 text-sm py-2'>Speakers</p>
               <h3 className='text-white text-4xl font-bold'>100+</h3>
            </div>
            <div className='flex justify-center max-md:hidden'>
               <div className='w-[1px] h-[30px] bg-blue-300'></div>
            </div>
            <div className='flex flex-col justify-center items-center'>
               <img src={Attendees} />
               <p className='text-cyan-500 text-sm py-2'>Attendees</p>
               <h3 className='text-white text-4xl font-bold'>1000+</h3>
            </div>
            <div className='flex justify-center max-md:hidden'>
               <div className='w-[1px] h-[30px] bg-blue-300'></div>
            </div>
            <div className='flex flex-col justify-center items-center'>
               <img src={AttendeesOnline} />
               <p className='text-cyan-500 text-sm py-2 text-center'>Users Online</p>
               <h3 className='text-white text-4xl font-bold'>5000+</h3>
            </div>

         </div>
      </div>
   )
}

export default statistic