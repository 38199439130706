import React from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';
import Footer from '../components/footer.jsx'
import ScrollToTop from '../components/scrolltotop.jsx'

function speakersessions2021() {
   return (
      <div className='w-full bg-gradient-to-r from-[#040227] via-[#1C005B] to-[#040227] font-body px-4'>
         <div className='max-w-[1280px] mx-auto'>
            <ScrollToTop />

            {/* Navigation */}
            <div className="max-w-[1280px] mx-auto text-white grid grid-cols-6 gap-6 pt-40">
               <Link to='/speakersessions2019'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2019</h3>
               </div></Link>

               <Link to='/speakersessions2020'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2020</h3>
               </div></Link>

               <Link to='/speakersessions2021'><div className="flex justify-center bg-pink-600 rounded-full py-2 cursor-pointer hover:bg-opacity-100 ease-out duration-300">
                  <h3>2021</h3>
               </div></Link>

               <Link to='/speakersessions2022'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2022</h3>
               </div></Link>

               <Link to='/speakersessions2023'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2023</h3>
               </div></Link>

               <Link to='/speakersessions2024'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2024</h3>
               </div></Link>
            </div>

            {/* Title */}
            <div className='items-center pt-20'>
               <h2 className='text-5xl font-bold text-white uppercase text-center'>Block Mountain 2021</h2>
            </div>

            {/* Main VDO */}
            <div className='pt-20'>
               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/2JVsWk9AuIY?si=6Yd632vdVvQi5kSw'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>
            </div>

            {/* Title */}
            <div className='pt-20'>
               <h2 className='text-3xl font-bold text-white uppercase text-center'>Speaker Session</h2>
            </div>

            {/* Speaker Session */}
            <div className='grid grid-cols-3 gap-3 max-md:grid-cols-1 pt-10'>
               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/TgucsoE6rLM?si=khZOXEDelfJOAnG0'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/xLmbZj1Yg6c?si=aXHdJfrMu1qfYEof'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/Mju0Uwc5qtI?si=WmFIlO8M-tSYaREG'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/HjrRQs05S2E?si=lcDG6rGX11pBiANE'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/UgZzIqgiSi8?si=dzwrEPkotPVM5eXu'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/Ww9246jvNVk?si=ZjZIrI9nfBKtYAMj'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/z0ox6TN-8pI?si=v-4PcXITnW6GbJHd'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/6msIc_0p4c4?si=lj6w6934zXBM9x_D'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/BojzquQkcPw?si=xUHP1c45yHvRIb83'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/R2f0zmsEfbk?si=E5XYYTS2raSjIcbj'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/5A-FBxsdpLk?si=U4TfqKiAIhxeSq37'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/GJhGGsCWolU?si=X1oaiBc3_WCUhSAO'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/2Ev59-Lrm6M?si=lmCyjykhl0NdYXm0'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/Ox8dDlYKhjQ?si=lZXdqb9hoGzZlkwf'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/qxoRwm1vmCA?si=hYOqmghpYpatU9Yl'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/fvoIyBtR_kw?si=JzQcaVcYS-pvebqK'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/lYELbnOXiD8?si=qRn8qBPvDuvb5Jut'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/dZqEW6YQtCM?si=H6rJfi21f_saHBsA'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/wDfs78lSyK0?si=MNTyBwsKn1uHcDN2'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/PRys734AZDA?si=rbg6zt2wDvXL-asQ'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/6CS3SsncW2U?si=ptHa3qVUfoxFSOOL'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/kPiU3vgA5mc?si=cmeCryxwWmBAmMws'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

            </div>
         </div>

         <Footer />
      </div>
   )
}

export default speakersessions2021