import React from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';
import Footer from '../components/footer.jsx'
import ScrollToTop from '../components/scrolltotop.jsx'

function speakersessions2023() {
   return (
      <div className='w-full bg-gradient-to-r from-[#040227] via-[#1C005B] to-[#040227] font-body px-4'>
         <div className='max-w-[1280px] mx-auto'>
            <ScrollToTop />

            {/* Navigation */}
            <div className="max-w-[1280px] mx-auto text-white grid grid-cols-6 gap-6 pt-40">
               <Link to='/speakersessions2019'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2019</h3>
               </div></Link>

               <Link to='/speakersessions2020'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2020</h3>
               </div></Link>

               <Link to='/speakersessions2021'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2021</h3>
               </div></Link>

               <Link to='/speakersessions2022'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2022</h3>
               </div></Link>

               <Link to='/speakersessions2023'><div className="flex justify-center bg-pink-600 rounded-full py-2 cursor-pointer hover:bg-opacity-100 ease-out duration-300">
                  <h3>2023</h3>
               </div></Link>

               <Link to='/speakersessions2024'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-pink-600 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2024</h3>
               </div></Link>
            </div>

            {/* Title */}
            <div className='items-center pt-20'>
               <h2 className='text-5xl font-bold text-white uppercase text-center'>Block Mountain 2023</h2>
            </div>

            {/* Main VDO */}
            <div className='pt-20'>
               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/gLarcrIJ14w?si=cuRGIPMTeGJ_ZISL'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>
            </div>

            {/* Title */}
            <div className='pt-20'>
               <h2 className='text-3xl font-bold text-white uppercase text-center'>Speaker Session</h2>
            </div>

            {/* Speaker Session */}
            <div className='grid grid-cols-3 gap-3 max-md:grid-cols-1 pt-10'>
               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/QC49KJFew4A?si=v47K4ElHRurBpJWv'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/nn88jswxz_M?si=cFp1yz4dCpU7_6Oo'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/mpCmfk7Gv-Y?si=jN2pOoPkv4SzrSJQ'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/_7yX7kAFg_c?si=DWlu3khE48mFiBrM'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/Et4lWacGKKM?si=t9zOe04Eq1x4mlxg'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/-WP3toDVvUg?si=K_tJ9RR_lYt3UNEJ'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/SahRFbf108s?si=WTRhBBxoouqD3Swp'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/8XpDjc2_CfA?si=lQX98kR4qeBYG1hy'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/L4llMMMNCVw?si=hLosMV3SVXj7A66u'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/tkoDxKSxVP0?si=Di1zJMz3srgIK6BY'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/_qCFsEocDBU?si=bheZzsqkKr-hNJ3l'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/eRfV7LSuMeQ?si=YdYLlYWRv7Vw4RjW'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/Hde13xtsBZQ?si=3lUiMaXsF9Wl9n1b'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/X5DcBKO60Iw?si=mrZXyMmALPTq23vM'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/l0xhIhemQPU?si=CIOP1Y7B8L14M5gp'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/250BQgpMIAk?si=5LHgWLB83c6wOZWV'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/WXx_G5d0JmM?si=CwCpoxLat8DJY137'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/Kxx9kXmW-X8?si=ehLol67lZ5StawuP'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/6xoByxNT2AI?si=sA4mlGWTvcJn3d77'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/HlE87vtjX5I?si=yv17rYRMkQ54tThu'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/TNgY2Iq6O_Y?si=NipqM9s5AiD0U9gP'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/xdjoq44z3w0?si=AQQCI9SvaQYK6gmS'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/jhPGJuyPsWY?si=fjjsMurr0Yp5ci4Y'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/2-zRMPKlCmc?si=DQ_WJY1MigGKdgAs'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/jBwHOLYLDAg?si=xUUXDMkQpY4JnzTA'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/JO70l8vLZWU?si=6ZnWcP0ZUNjY0Wd1'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/BTN-zHClnNQ?si=DwYgNT_x-CMoGRle'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/IN9jgmuTJz4?si=swut81E1qILGnnHV'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/kUDWVmCsyGs?si=F-m--GT1kb9q8C85'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/O3Cct7UfYtY?si=KwZfqsoI73k9TEg4'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/jQGR8cR84PA?si=JVHwSoATKrRgGuhO'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/nvpFvptTeVY?si=_LG9ArJ65qKIpqEl'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/jVGV2oPI-Q4?si=jO7KXyBOl_KpAryn'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/rHMBDJY4Rv4?si=0PnIKNA1984mgwfI'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

            </div>
         </div>

         <Footer />
      </div>
   )
}

export default speakersessions2023