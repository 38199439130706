import React from 'react';
import Hero from '../components/hero.jsx'
import BlockAboutEvent from '../components/block_about_event.jsx'
import Statistic from '../components/statistic.jsx'
import Quote from '../components/quote.jsx'
import Speakers from '../components/speakers.jsx'
import Speakersession from '../components/speakersession.jsx'
import Tickerfast from '../components/tickerfast.jsx'
import Venue from '../components/venue.jsx'
import Sponsors from '../components/sponsors.jsx'
import BuyTicket from '../components/buyticket.jsx'
import Footer from '../components/footer.jsx';


function Home() {
   return (
      <div>
         <Hero />
         <BlockAboutEvent />
         <Statistic />
         <Quote />
         <Speakers />
         <Speakersession />
         <Tickerfast />
         <Venue />
         <Sponsors />
         <BuyTicket />
         <Footer />
      </div>
   );
}

export default Home;
