import Navbar from './navbar';
import Footer from './footer'
import Home from '../pages/home';
import Speakers from '../pages/speakers';
import Speakersessions2019 from '../pages/speakersessions2019';
import Speakersessions2020 from '../pages/speakersessions2020';
import Speakersessions2021 from '../pages/speakersessions2021';
import Speakersessions2022 from '../pages/speakersessions2022';
import Speakersessions2023 from '../pages/speakersessions2023';
import Speakersessions2024 from '../pages/speakersessions2024';
import SpeakerApplications from '../components/speakerapplicatons'
import Partners from '../pages/partners';
import SeoImage from './seoimage'
import OgImage from '../img/og-live-preview.jpg'



import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';


const Router = () => {
   const Layout = () => {
      return (
         <div className='min-h-screen flex flex-col '>
            <SeoImage customImage={'https://blockmountaincnx.com' + OgImage} />
            <Navbar />
            <Outlet />
         </div>
      );
   }

   const BrowserRoutes = () => {
      return (
         <>
            <BrowserRouter>
               <Routes>
                  <Route path="/" element={<Layout />}>
                     <Route path="/" element={<Home />} />
                     <Route path="Speakers" element={<Speakers />} />
                     <Route path="Speakersessions2019" element={<Speakersessions2019 />} />
                     <Route path="Speakersessions2020" element={<Speakersessions2020 />} />
                     <Route path="Speakersessions2021" element={<Speakersessions2021 />} />
                     <Route path="Speakersessions2022" element={<Speakersessions2022 />} />
                     <Route path="Speakersessions2023" element={<Speakersessions2023 />} />
                     <Route path="Speakersessions2024" element={<Speakersessions2024 />} />
                     <Route path="SpeakerApplications" element={<SpeakerApplications />} />
                     
                     <Route path="Partners" element={<Partners />} />
                  </Route>
               </Routes>
            </BrowserRouter>
         </>
      );
   }

   return (
      <BrowserRoutes />
   )
}

export default Router