import React from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';

function speakersession() {
   return (
      <div className='w-full bg-gradient-to-r from-[#040227] via-[#1C005B] to-[#040227] font-body px-4 pt-10'>
         <div className='max-w-[1280px] mx-auto'>
            <div className='flex flex-row justify-between items-center '>
               <h2 className='text-3xl font-bold text-white uppercase text-center max-md:text-left'>2024 Speaker Session</h2>
               <div className='w-[250px]'>
                  <Link to='/speakersessions2024'>
                     <button-outline> <p className=''>View All</p></button-outline>
                  </Link>
               </div>

            </div>

            <div className='grid grid-cols-3 gap-3 max-md:grid-cols-1 pt-10'>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://www.youtube.com/watch?v=KfrVXCSU59E'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://www.youtube.com/watch?v=KfrVXCSU59E'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://www.youtube.com/watch?v=KfrVXCSU59E'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

            </div>
         </div>
      </div>
   )
}

export default speakersession